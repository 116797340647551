import React, { Suspense, useEffect, useState } from "react";
import Layout from "./components/Layout/";
import { Switch, BrowserRouter as Router, withRouter } from "react-router-dom";

import AuthContext from "./contexts/auth";
import FilteredRoutes from "./utils/filteredRoutes";
import localStorage from "./utils/storage";

// Import Css
import "./assets/css/materialdesignicons.min.css";
import "./Apps.scss";

import "./assets/css/color.css";

// Include Routes
import routes from "./routes";
import ShopShipContext from "./contexts/shopShip";

function withLayout(WrappedComponent) {
  // ...and returns another component...
  return class extends React.Component {
    render() {
      return (
        <Layout>
          <WrappedComponent></WrappedComponent>
        </Layout>
      );
    }
  };
}

function App() {
  const token = localStorage.getToken();
  const [user, setUser] = useState();
  const [productDetails, setProductDetails] = useState([]);

  const poplishingCurrentUser = () => {
    const user = localStorage.getCurrentUser();
    setUser(user);
  };

  const handleSettingUpProduct = (products) => {
    localStorage.storeProducts(products);
    setProductDetails(products);
  };

  useEffect(() => {
    if (token && !user) poplishingCurrentUser();
    return () => {};
  }, [token, user]);

  const Loader = () => {
    return (
      <div id="preloader">
        <div id="status">
          <div className="spinner">
            <div className="double-bounce1"></div>
            <div className="double-bounce2"></div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <React.Fragment>
      <AuthContext.Provider value={{ user, setUser }}>
        <ShopShipContext.Provider
          value={{ productDetails, onProductsSet: handleSettingUpProduct }}
        >
          <Router>
            <Suspense fallback={Loader()}>
              <Switch>
                {routes.map((route, idx) =>
                  route.isWithoutLayout ? (
                    <FilteredRoutes
                      path={route.path}
                      exact={route.exact}
                      component={route.component}
                      access={route.access}
                      key={idx}
                    />
                  ) : (
                    <FilteredRoutes
                      path={route.path}
                      exact
                      component={withLayout(route.component)}
                      access={route.access}
                      key={idx}
                    />
                  )
                )}
              </Switch>
            </Suspense>
          </Router>
        </ShopShipContext.Provider>
      </AuthContext.Provider>
    </React.Fragment>
  );
}

export default withRouter(App);
