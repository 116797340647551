import React from "react";

const Home = React.lazy(() => import("./pages/Home"));
const About = React.lazy(() => import("./pages/About"));
const Contact = React.lazy(() => import("./pages/Contact"));
const Services = React.lazy(() => import("./pages/Services"));
const Profile = React.lazy(() => import("./pages/Profile/index"));
const Tracking = React.lazy(() => import("./pages/Tracking"));
const TrackingError = React.lazy(() => import("./pages/Tracking/trackError"));
const Book = React.lazy(() => import("./pages/Book"));
const ShopAndShip = React.lazy(() => import("./pages/ShopAndShip"));
const CheckOut = React.lazy(() => import("./pages/ShopAndShip/checkOut"));

// Error Page
const ErrorPage = React.lazy(() => import("./pages/Error"));

// Auth Page
const SignIn = React.lazy(() => import("./pages/Auth/SingIn"));
const SignUp = React.lazy(() => import("./pages/Auth/SignUp"));
const ForgetPassword = React.lazy(() => import("./pages/Auth/ForgetPassword"));
const RegisteredDone = React.lazy(() => import("./pages/Auth/RegisteredDone"));

const routes = [
  //Home
  { path: "/", component: Home, access: "both" },

  //Services
  { path: "/services", component: Services, access: "both" },

  //About
  { path: "/about", component: About, access: "both" },

  //Contact Us
  { path: "/contactUs", component: Contact, access: "both" },

  // profile
  { path: "/profile", component: Profile, access: "authorized" },

  // track
  { path: "/:awb/tracking", component: Tracking, access: "both" },
  {
    path: "/trackingError",
    component: TrackingError,
    access: "both",
    isWithoutLayout: true,
  },

  //Book
  { path: "/booking", component: Book, access: "authorized" },

  //ShipAndShop
  { path: "/shopship", component: ShopAndShip, access: "authorized" },
  { path: "/shopship/checkOut", component: CheckOut, access: "authorized" },

  //Auth
  {
    path: "/auth/sign_in",
    isWithoutLayout: true,
    component: SignIn,
    access: "notAuthorized",
  },
  {
    path: "/auth/sign_up",
    isWithoutLayout: true,
    component: SignUp,
    access: "notAuthorized",
  },
  {
    path: "/auth/successfull_signup",
    isWithoutLayout: true,
    component: RegisteredDone,
    access: "notAuthorized",
  },
  {
    path: "/auth/forgot_password",
    isWithoutLayout: true,
    component: ForgetPassword,
    access: "notAuthorized",
  },

  {
    path: "/error",
    component: ErrorPage,
    access: "both",
    isWithoutLayout: true,
  },

  //Error Page
  { component: ErrorPage, isWithoutLayout: true, exact: false },
];

export default routes;
