import React from "react";
import { Route, Redirect } from "react-router-dom";

// import useAuth from "../auth/useAuth";
import authStorage from "./storage";

function FilteredRoutes(props) {
  // const locaiton = useLocation();
  // const { user } = useAuth();
  const token = authStorage.getToken();

  if (token && props.access === "authorized") {
    return <Route {...props} />;
  }
  if (token === null && props.access === "notAuthorized") {
    return <Route {...props} />;
  }
  if (props.access === "both") {
    return <Route {...props} />;
  }

  return <Redirect to={token ? "/" : "/auth/sign_in"} />;
}

export default FilteredRoutes;
