import jwtDecode from "jwt-decode";

const authToken = "authToken";
const shopAndShop = "shopAndShop";

const storeToken = (jwt) => {
  try {
    localStorage.setItem(authToken, jwt);
  } catch (error) {
    console.log("Error storing the auth token", error);
  }
};

const getCurrentUser = () => {
  try {
    const jwt = localStorage.getItem(authToken);

    return jwt ? jwtDecode(jwt) : null;
  } catch (error) {
    console.log("Error getting the current user", error);
  }
};

const getToken = () => {
  return localStorage.getItem(authToken);
};

const removeToken = async () => {
  try {
    localStorage.removeItem(authToken);
  } catch (error) {
    console.log("Error removing the auth token", error);
  }
};

const storeProducts = (products) => {
  try {
    localStorage.setItem(shopAndShop, JSON.stringify(products));
  } catch (error) {
    console.log("Error storing the auth token", error);
  }
};

const getProducts = () => {
  return JSON.parse(localStorage.getItem(shopAndShop));
};

const removeProducts = () => {
  return localStorage.removeItem(shopAndShop);
};

const Storage = {
  getToken,
  getCurrentUser,
  removeToken,
  storeToken,
  storeProducts,
  getProducts,
  removeProducts,
};

export default Storage;
